import { Link, graphql } from "gatsby"
import React from 'react'
import MainLayout from '../layouts/MainLayout'
import SEO from "../components/SEO"
import FirstLevelBanner from '../components/our-work/first-level/Banner'

import Content from "../components/our-work/second-level/Content"
import Media from "../components/our-work/second-level/Media"

export default function ProjectTemplate({ data }) {
    return (
        <MainLayout>
            <SEO title={`${data.strapiPages.Seo.title}`} description={`${data.strapiPages.Seo.description}`} />
            <FirstLevelBanner sources={data.strapiPages.HeroImage} />
            <section className="container">
                <h1 className="page-title">{data.strapiPages.Header}</h1>
                <div className="work-container">
                <Content content={data.strapiPages.Description} projectUpdates={null} />
                </div>
            </section>
            <Media imageSource={data.strapiPages.Pictures} videoSource={null} />
        </MainLayout>
    )
}

export const query = graphql`
  query($slug: String!) {
    strapiPages(Slug: { eq: $slug }) {
        Header
        Description
        Pictures {
            id
            width
            height
            alternativeText
            url
            
        }
        HeroImage {
            desktopImage {
                localFile{
                    publicURL
                }
            }
            mobileImage{
                localFile{
                    publicURL
                }
            }
        }
        Seo {
            description
            title
        }
    }
  }
`