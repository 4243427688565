import React from "react"
import iconPlay from "../../../assets/images/icon-play.svg"

const isElementVideo = $el => {
  const name = $el.tagName.toLowerCase()
  const isVideo = name === "iframe"
  return isVideo
}

const Media = ({ imageSource, videoSource }) => {
  const handlePlayClick = e => {
    const $img = e.target
    const $mediaImg = $img.nextSibling
    const $video = $mediaImg.nextSibling
    const shouldDisplayVideo = isElementVideo($video)

    if (shouldDisplayVideo) {
      const $caption = $video.nextSibling
      const $elementsWillBeHidden = [$img, $mediaImg, $caption]
      $video.style.height = `${$mediaImg.height}px`

      $elementsWillBeHidden.forEach(i => {
        i.hidden = true
      })

      $video.src = $video.dataset.src
      $video.hidden = false
    }
  }

  return (
    <section className="container soundingLouder-secondLevel-media">
      <div className="media-sources">
        {imageSource && imageSource.map((source, index) => (
          <div key={index} className="outer">
            <div className="inner">
              <img
                src={`${process.env.API_URL}${source.url}`}
                width="488"
                height="275"
                alt={source.alternativeText}
                className="media-img"
              />
              <span>{source.alternativeText}</span>
            </div>
          </div>
        ))}
        {videoSource && videoSource.length > 0 && videoSource.map(video => (
          <div className="outer">
            <div className="inner">
              <img
                src={iconPlay}
                alt="video-play"
                width="108"
                height="108"
                className="icon-play"
                role="presentation"
                onClick={handlePlayClick}
                onKeyDown={handlePlayClick}
              />
              <img
                src={`${video.coverImage.localFile.publicURL}`}
                width="488"
                height="275"
                alt={video.description}
                className="media-img"
              />
              <iframe
                title="youtube-video-iframe"
                width="488"
                height="275"
                data-src={`https://www.youtube.com/embed/${video.code}?autoplay=1&rel=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                hidden
              ></iframe>
              <span>{video.description}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Media
