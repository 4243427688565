import React, { useEffect } from "react"
import ReactMarkdown from "react-markdown"

const Content = ({ content = '', projectUpdates, donationStatus = false }) => {
  const find = '<img src="';
  const re = new RegExp(find, 'g');
  const doc = new RegExp('<a href="\/', 'g')

  useEffect(() => {
    const type = window.location.hash.replace('#', '')
    if (document.getElementById(type) !== null) {
      collapseButton(document.getElementById(type))
    } else {
      collapseButton(document.getElementById(0))
    }
  })

  const collapseButton = e => {
    if (e) {
      const $contentList = e.currentTarget ? e.currentTarget.nextElementSibling : e.nextElementSibling
      if (e.currentTarget && e.currentTarget.lastChild) { e.currentTarget.lastChild.classList.toggle("close") }
      else {
        e.lastChild.classList.toggle("close")
      }
      $contentList.classList.toggle("content-links-active")
      if ($contentList.style.maxHeight) {
        $contentList.style.maxHeight = null
      } else {
        $contentList.style.maxHeight = `${$contentList.scrollHeight}px`
      }
    }
  }

  return (
    <div className={donationStatus ? "work-content" : "work-content work-content-with-no-padding"}>
      <ReactMarkdown
        source={content.replace(re, `<img src="${process.env.API_URL}`).replace(doc, `<a href="${process.env.API_URL}/`)}
        escapeHtml={false}
        className="ck-content"
      />
      <div className="content">
        <div className="menu">
          {(projectUpdates && projectUpdates.length > 0) &&
            projectUpdates.map((projectUpdate, index) => {
              return (
                <div className="menu-item">
                  <button type="button" className="btn btn-primary" id={index} onClick={collapseButton}>
                    <h4>{projectUpdate.Header}{projectUpdate.ProjectName && ` - ${projectUpdate.ProjectName}`}{projectUpdate.Region && ` - ${projectUpdate.Region}`}{projectUpdate.Date && ` - ${projectUpdate.Date}`}</h4>
                    <div className="image"></div>
                  </button>
                  <div className="content-links">
                    <ReactMarkdown
                      source={projectUpdate.Content.replace(re, `<img src="${process.env.API_URL}`).replace(doc, `<a href="${process.env.API_URL}/`)}
                      escapeHtml={false}
                      className="ck-content"
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Content
